/* TopCenteredLayout.css */

.layout {
    /* min-height: 100vh; Ensures the layout takes full screen height */
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: flex-start; /* Aligns content to the top */
    background-color: #f0f2f5; /* Optional: Ant Design's default background color */
  }
  
  .top-centered-content {
    width: 100%; /* Full width content */
    max-width: 100%; /* Optional: Constrain the maximum width */
    margin-top: 0px; /* Position content slightly down from the top */
    background: #fff; /* White background for the content area */
    padding: 0px; /* Padding around the content */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* Optional: Add a shadow for better visual */
  }
  