/* FullPageLayout.css */

.logo {
  height: 32px;
  margin: 16px;
  /* background: rgba(255, 255, 255, 0.3); */
}

.trigger {
  padding: 0 0px;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;
}

.site-layout-background {
  background: #fff;
}

@media (max-width: 768px) {
  .trigger {
    padding: 0 5px;
  }

  .ant-layout-sider-collapsed .ant-menu-item {
    text-align: center;
  }
}