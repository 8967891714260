/* ProductCard.css */

.product-price {
    color: #ff5722;
    font-size: 16px;
    font-weight: bold;
  }
  
  .product-sold {
    color: #888;
    font-size: 12px;
  }
  