@import url('https://fonts.googleapis.com/css2?family=Beau+Rivage&family=Courgette&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
  font-family: 'prompt';
  font-size: 14px;
  background-color: rgb(255, 255, 255);
  max-height: '100vh';
  margin: 0;
}


.App {
  text-align: center;
  font-family: 'Prompt', sans-serif;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.login-container, .dashboard-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.error {
  color: red;
}

.signup-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.signup-container h2 {
  margin-bottom: 20px;
  text-align: center;
}

.ant-form-item-label > label {
  font-weight: 600;
}

/* App.css */
/* App.css */
.transparent-menu {
  background-color: transparent !important;
  border-bottom: none;
  display: flex;
  justify-content: flex-start;
  overflow: visible;
}

.transparent-menu .ant-menu-item {
  color: white; /* Default text color */
  flex: none;
}

.transparent-menu .ant-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.transparent-menu .ant-menu-item-selected {
  color: white !important; /* Ensure selected item's font color is white */
  background-color: transparent !important; /* Remove the default background */
}

/* Media Query to handle screen size adjustments */
@media (max-width: 768px) {
  .transparent-menu {
    flex-wrap: nowrap;
  }

  .transparent-menu .ant-menu-item {
    flex-grow: 1;
    text-align: center;
  }
}